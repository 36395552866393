import { NavLink, useNavigate, useSearchParams } from "@remix-run/react";
import { cn } from "@sutro/studio2-quarantine/lib/utils";
import { either } from "@sutro/studio2-quarantine/sutro-api/Api";
import { useAuthenticatedSutroApi } from "@sutro/studio2-quarantine/sutro-api/api-provider";
import {
  Bell,
  BookOpen,
  HelpCircle,
  Home,
  LogOut,
  MessagesSquare,
  Puzzle,
  Rocket,
  Save,
  User,
  Users,
  X,
} from "lucide-react";
import { ReactElement, ReactNode, useCallback, useState } from "react";
import { toast } from "sonner";

import logo from "~/assets/sutro.svg";
import { PosthogNavButton } from "~/components/app/devtools/posthog-nav-button";
import { Button } from "~/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { useFeatureFlags } from "~/lib/hooks/use-feature-flag";
import { useStudio } from "~/lib/hooks/use-studio";
import { useProfile } from "~/lib/use-profile";
import { useIntercom } from "~/providers/IntercomProvider/intercom-provider";

import { DevToolsNavButton } from "./devtools/dev-tools";
import { FeatureRequestDialog } from "./feature-request-dialog";
import { StudioUserAvatar } from "./studio-user-avatar";

function TopNavigationBarLink({
  label,
  href,
  icon,
}: {
  label: string;
  href: string;
  icon: ReactElement;
}) {
  return (
    <NavLink
      to={href}
      className={({ isActive }) =>
        cn(
          "font-medium pt-6 px-4 pb-5 border-b-[4px] text-white inline-flex items-center space-x-2 text-sm transition-colors",
          {
            "border-blue-600": isActive,
            "border-transparent hover:border-blue-900": !isActive,
          }
        )
      }
    >
      {icon}
      <span>{label}</span>
    </NavLink>
  );
}

export function BelowNavigationBarFullSizeContainer({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return <div className={cn("h-[calc(100%-68px)]", className)}>{children}</div>;
}

function SutroLogo() {
  return (
    <NavLink to="/">
      <img src={logo} className="mr-8 h-6 text-white" alt="Sutro logo" />
    </NavLink>
  );
}

function ProfileMenu() {
  const { logout, currentUser } = useProfile();
  const navigate = useNavigate();

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <StudioUserAvatar
          studioUser={currentUser}
          className="size-8 cursor-pointer"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem
            onClick={() => {
              navigate("/user");
            }}
          >
            <User className="mr-2 size-4" />
            <span>User settings</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={logout}>
          <LogOut className="mr-2 size-4" />
          <span>Logout</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function AssistanceMenu() {
  const authApi = useAuthenticatedSutroApi();
  const [isFeatureRequestDialogOpen, setIsFeatureRequestDialogOpen] =
    useState(false);
  const intercom = useIntercom();

  const openIntercom = useCallback(() => {
    intercom("show");
  }, [intercom]);

  const submitFeatureRequest = useCallback(
    (featureRequest: { subject: string; description: string }) => {
      const throwError = () => {
        toast.error("Failed to submit feature request");
      };

      void authApi.post("/submitFeatureRequest", featureRequest).then(
        either(throwError).or(() => {
          toast.success("Feature request submitted successfully");
        })
      );
    },
    [authApi]
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary">
          <HelpCircle className="mr-2 size-4" />
          Assistance
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="p-3">
        <DropdownMenuItem
          className="flex cursor-pointer items-start justify-start px-4 py-3"
          onClick={() => {
            setIsFeatureRequestDialogOpen(true);
          }}
        >
          <Puzzle className="size-5 text-muted-foreground" />
          <div className="flex flex-col pl-3">
            <span className="text-sm">Missing a feature?</span>
            <span className="text-sm text-muted-foreground">
              Let us know what we can build next
            </span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="flex cursor-pointer items-start justify-start px-4 py-3"
          onClick={openIntercom}
        >
          <MessagesSquare className="size-5 text-muted-foreground" />
          <div className="flex flex-col pl-3">
            <span className="text-sm">Need immediate help?</span>
            <span className="text-sm text-muted-foreground">
              Chat with our of our developer advocates
            </span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem className="flex cursor-pointer items-start justify-start px-4 py-3">
          <BookOpen className="size-5 text-muted-foreground" />
          <a
            href="https://docs.withsutro.com/"
            target="_blank"
            className="flex flex-col pl-3"
            rel="noreferrer noopener"
          >
            <span className="text-sm">Understand the core better</span>
            <span className="text-sm text-muted-foreground">
              Dive deeper into our tutorials and resources
            </span>
          </a>
        </DropdownMenuItem>
      </DropdownMenuContent>

      <FeatureRequestDialog
        open={isFeatureRequestDialogOpen}
        onOpenChange={setIsFeatureRequestDialogOpen}
        onSubmit={submitFeatureRequest}
      />
    </DropdownMenu>
  );
}

export function StandardTopNavigationBar() {
  return (
    <TopNavigationBar>
      <SutroLogo />
      <TopNavigationBarLink
        label="Dashboard"
        href="/"
        icon={<Home className="size-4" />}
      />
      <TopNavigationBarLink
        label="Community"
        href="https://www.sutro.xyz"
        icon={<Users className="size-4" />}
      />
      <TopNavigationBarLink
        label="Learn"
        href="https://www.sutro.xyz"
        icon={<BookOpen className="size-4" />}
      />
      <div className="flex grow items-center justify-center self-stretch py-3">
        {/* Not needed right now */}
        {/* <SearchBar /> */}
      </div>
      <div className="mr-2 flex items-center space-x-2">
        <Button variant="secondary" className="px-2.5">
          <Bell className="size-5" />
        </Button>
        <ProfileMenu />
      </div>
    </TopNavigationBar>
  );
}

export function CreatorTopNavigationBar() {
  const navigate = useNavigate();

  return (
    <TopNavigationBar>
      <SutroLogo />

      <div className="flex grow items-center justify-center self-stretch py-3">
        <span className="text-base font-medium text-white">
          Create a new app
        </span>
      </div>
      <Button
        variant="ghost"
        className="hover:bg-gray-700"
        onClick={() => navigate("/")}
      >
        <X className="text-white" />
      </Button>
    </TopNavigationBar>
  );
}

export function StudioTopNavigationBar() {
  const [_, setSearchParams] = useSearchParams();
  const executeSave = useStudio((s) => s.executeSave);
  const flags = useFeatureFlags("DISABLE_AUTOSAVE", "PUBLISH_APP");

  return (
    <TopNavigationBar>
      <SutroLogo />
      <div className="flex grow items-center justify-center self-stretch py-3">
        {/* Not needed right now */}
        {/* <SearchBar /> */}
      </div>
      <div className="mr-2 flex items-center space-x-2">
        <PosthogNavButton />
        <DevToolsNavButton
          onClick={() => {
            setSearchParams((prev) => {
              prev.set("showDevTools", "true");
              return prev;
            });
          }}
        />
        {flags.PUBLISH_APP && (
          <Button
            variant="success"
            onClick={() => {
              setSearchParams((prev) => {
                prev.set("showPublish", "true");
                return prev;
              });
            }}
          >
            <Rocket className="mr-2 size-4" />
            Publish
          </Button>
        )}
        {flags.DISABLE_AUTOSAVE && (
          <Button
            onClick={() => {
              void executeSave();
            }}
          >
            <Save className="mr-2 size-4" />
            Save
          </Button>
        )}
        <AssistanceMenu />
        <ProfileMenu />
      </div>
    </TopNavigationBar>
  );
}

function TopNavigationBar({ children }: { children: ReactNode }) {
  return (
    <div className="sticky top-0 z-50 flex h-[68px] w-full items-center bg-[#18181b] px-4">
      {children}
    </div>
  );
}
