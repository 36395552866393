import { useMonaco } from "@monaco-editor/react";
import { cn } from "@sutro/studio2-quarantine/lib/utils";
import {
  Dispatch,
  lazy,
  SetStateAction,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { StateSetter } from "sutro-common";
import { AnyObject } from "sutro-common/object-types";
import { slangSyntax } from "sutro-common/slang/syntax-highlighting";

export function useStateUntilInitialStateChanges<T extends AnyObject | string>(
  initialState: T
): [T, StateSetter<T>, () => void] {
  const [globalState, setGlobalState] = useState<{
    initialState: symbol | T;
    state: T;
  }>({ initialState, state: initialState });

  if (globalState.initialState !== initialState) {
    setGlobalState({ initialState, state: initialState });
  }

  const stateSetter: StateSetter<T> = useCallback(
    (newStateOrFn) => {
      if (typeof newStateOrFn === "function") {
        setGlobalState((currentGlobalState) => ({
          state: newStateOrFn(currentGlobalState.state),
          initialState,
        }));
      } else {
        setGlobalState({ state: newStateOrFn, initialState });
      }
    },
    [initialState]
  );

  return [
    globalState.state,
    stateSetter,
    // This is a flush fn that can always be used to clear
    () => {
      setGlobalState({ state: globalState.state, initialState: Symbol() });
    },
  ];
}

const Editor = lazy(() =>
  import("@monaco-editor/react").then((m) => ({
    default: m.Editor,
  }))
);

export const JsonEditor = <T extends Record<string, unknown> | unknown[]>({
  object,
  text,
  onEdit,
  className,
  language = "json",
}: {
  object?: T;
  text?: string;
  onEdit: Dispatch<SetStateAction<string>>;
  className?: string;
  language?: "json" | "slang";
}) => {
  const monaco = useMonaco();
  const [localText, setLocalText] = useStateUntilInitialStateChanges(
    text ?? JSON.stringify(object, null, 2)
  );

  // We don't want to fire onChange when the text or object property changes
  const suppressChange = useRef(true);

  useEffect(() => {
    if (!monaco) {
      return;
    }
    monaco.languages.register({ id: "slang" });
    monaco.languages.setMonarchTokensProvider("slang", slangSyntax);
  }, [monaco]);

  useEffect(() => {
    suppressChange.current = false;
    return () => {
      suppressChange.current = true;
    };
  }, [text, object]);

  return (
    <div className={cn("border border-black h-80", className)}>
      <Suspense fallback={null}>
        <Editor
          value={localText}
          language={language}
          onChange={(newText) => {
            if (suppressChange.current || newText === undefined) {
              return;
            }
            if (text !== undefined) {
              setLocalText(newText);
              onEdit(newText);
            } else {
              setLocalText(newText);
              try {
                const parsed = JSON.parse(newText);
                onEdit(parsed);
              } catch (e) {
                console.debug(e);
              }
            }
          }}
        />
      </Suspense>
    </div>
  );
};
