import type { Intercom } from "./types";

let memoizedPlaceholder: Intercom | null = null;
/**
 * Generates an exotic function object that acts as a placeholder for the real Intercom object.
 *
 * This will record all the calls that made before Intercom is loaded fully and then replay them when Intercom is loaded.
 */
export const getIntercomPlaceholder = (): Intercom => {
  if (memoizedPlaceholder === null) {
    // Don't change these property names - I think Intercom relies on them
    const i = function (...args: unknown[]) {
      i.c(...args);
    };
    i.q = [] as unknown[][];
    i.c = function (...args: unknown[]) {
      i.q.push(args);
    };
    i.booted = false;

    // Normally, I'd use `satisfies` here, but the typing of `Intercom` is hard to manipulate.
    // In this function, the return type of `i.c` in our code is `void`, but the `Intercom` type specifies that it should be `ReturnType<IntercomCommandSignature[Command]>`
    // However, `ReturnType<IntercomCommandSignature[Command]>` always resolves to `void`... so this is good enough
    memoizedPlaceholder = i as Intercom;
  }

  return memoizedPlaceholder;
};
