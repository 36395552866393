import { useEffect, useState } from "react";

import { useProfile } from "./use-profile";

async function getGravatarUrl(email?: string) {
  const msgBuffer = new TextEncoder().encode(email);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return `https://gravatar.com/avatar/${hashHex}?d=404`;
}

/**
 * This hook returns a gravatar URL for the current user, if they have opted to use Gravatar.
 */
export function useGravatar({ force = false }: { force?: boolean } = {}):
  | string
  | null {
  const { currentUser } = useProfile();
  const [gravatarUrl, setGravatarUrl] = useState<string | null>(null);

  useEffect(() => {
    if (force || currentUser?.avatar?.source === "default") {
      void getGravatarUrl(currentUser?.identity).then((url) =>
        setGravatarUrl(url)
      );
    } else {
      setGravatarUrl(null);
    }
  }, [currentUser, force]);

  return gravatarUrl;
}
