import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../ui/dialog";
import { Form } from "../ui/form";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { Textarea } from "../ui/textarea";

const featureRequestSchema = z.object({
  subject: z.string().min(1, "Subject is required"),
  description: z.string().min(1, "Description is required"),
});

export const FeatureRequestDialog = ({
  open,
  onSubmit,
  onOpenChange,
}: {
  open: boolean;
  onSubmit: (data: { subject: string; description: string }) => void;
  onOpenChange: (value: boolean) => void;
}) => {
  const form = useForm({
    resolver: zodResolver(featureRequestSchema),
    defaultValues: {
      subject: "",
      description: "",
    },
  });

  const handleSubmit = form.handleSubmit((data) => {
    onSubmit(data);
    onOpenChange(false);
  });

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl">
        <Form {...form}>
          <form onSubmit={handleSubmit}>
            <DialogHeader>
              <DialogTitle>Feature Request</DialogTitle>
              <DialogDescription>
                Let us know what you&apos;d like Sutro to learn next.
              </DialogDescription>
            </DialogHeader>
            <div className="my-5 flex w-full flex-1 flex-col gap-4 pl-8">
              <div className="flex items-center gap-4">
                <Label htmlFor="subject" className="w-32 text-right">
                  Subject
                </Label>
                <Input
                  id="subject"
                  {...form.register("subject")}
                  containerClassName="grow"
                  error={form.formState.errors.subject?.message}
                />
              </div>
              <div className="flex w-full flex-1 items-start gap-4">
                <Label
                  htmlFor="description"
                  className="w-32 whitespace-nowrap text-right"
                >
                  Feature description
                </Label>
                <Textarea
                  id="description"
                  {...form.register("description")}
                  containerClassName="max-h-[50vh] w-full grow"
                  rows={4}
                  error={form.formState.errors.description?.message}
                />
              </div>
            </div>
            <DialogFooter>
              <Button type="submit">Send</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
