/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { loadThirdPartyScript } from "sutro-common";

import { getIntercomPlaceholder } from "./get-intercom-placeholder";
import { Intercom } from "./types";

/**
 * The IntercomProvider provides access to the Intercom object (see https://developers.intercom.com/installing-intercom/web/methods)
 *
 * Normally, `Intercom` is accessed via the `window` object, but we want to avoid doing that directly.
 *
 * If you need to access the Intercom object, use the `useIntercom` hook.
 *
 */

enum IntercomLoadingStatus {
  NOT_REQUESTED,
  LOADING,
  LOADED,
}
if (typeof window !== "undefined") {
  //@ts-expect-error: _Intercom is defined on window, but we don't really want anyone to use it directly
  window._Intercom = getIntercomPlaceholder();
}

const IntercomContext = createContext<Intercom>(getIntercomPlaceholder());

/**
 *
 */
export const useIntercom = () => {
  const intercom = useContext(IntercomContext);
  if (intercom === undefined) {
    throw new Error(
      "IntercomProvider: useIntercom must be used within an IntercomProvider"
    );
  }
  return intercom;
};

export const IntercomProvider = ({
  children,
  appId,
}: PropsWithChildren<{ appId?: string }>) => {
  // Since intercom is a function, we can't just pass in the value directly to useState
  // Instead, we pass in a function that returns the value
  const [intercom, setIntercom] = useState<Intercom>(getIntercomPlaceholder);
  // We use a ref to track the loading status, rather than a state variable, because we don't need to re-render when the loading status changes
  const loading = useRef<IntercomLoadingStatus>(
    IntercomLoadingStatus.NOT_REQUESTED
  );

  useEffect(() => {
    if (appId === undefined) {
      console.warn(
        `IntercomProvider: No appId provided. As a result, no events will be tracked.`
      );
      // Just make Intercom a no-op
      setIntercom(() => (() => {}) as unknown as Intercom);
      loading.current = IntercomLoadingStatus.LOADED;
    } else {
      console.debug(`IntercomProvider: Loading Intercom with appId ${appId}`);
    }

    if (
      appId !== undefined &&
      loading.current === IntercomLoadingStatus.NOT_REQUESTED
    ) {
      loadThirdPartyScript(`https://widget.intercom.io/widget/${appId}`);
      loading.current = IntercomLoadingStatus.LOADING;
    }
  }, [appId]);

  useEffect(() => {
    if (intercom === undefined || appId === undefined) {
      return;
    }
    intercom("boot", {
      api_base: "https://api.intercom.io",
      app_id: appId,
      background_color: "#18181B",
      action_color: "#1D4ED8",
    });
  }, [intercom, appId]);

  useEffect(() => {
    // Check if the Intercom property already exists on the window object
    if (!Object.getOwnPropertyDescriptor(window, "Intercom")) {
      // If it doesn't exist, define the property
      Object.defineProperty(window, "Intercom", {
        configurable: true,
        enumerable: true,
        get() {
          return this._Intercom;
        },
        set(val) {
          loading.current = IntercomLoadingStatus.LOADED;
          // This has to be of the function form because `val` is a function, so passing it in as a value
          // confuses the React state setter
          setIntercom(() => val);
          this._Intercom = val;
        },
      });
    } else {
      // If it already exists, we can't redefine it, so we'll need to use a different approach
      const originalIntercom = window.Intercom;
      // @ts-expect-error Property 'booted' is missing in type '<Command extends IntercomCommand>(command: Command, ...params: Parameters<IntercomCommandSignature[Command]>) => string | void' but required in type 'IntercomStatic'.
      window.Intercom = function (...args) {
        loading.current = IntercomLoadingStatus.LOADED;
        setIntercom(() => originalIntercom);
        // @ts-expect-error Property 'booted' is missing in type '<Command extends IntercomCommand>(command: Command, ...params: Parameters<IntercomCommandSignature[Command]>) => string | void' but required in type 'IntercomStatic'.
        return originalIntercom.apply(this, args);
      };
    }
  }, []);

  return (
    <IntercomContext.Provider value={intercom}>
      {children}
    </IntercomContext.Provider>
  );
};
