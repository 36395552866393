import { cn } from "@sutro/studio2-quarantine/lib/utils";
import { FlaskConical } from "lucide-react";
import posthogJs from "posthog-js";

import config from "~/app.config";
import { Button } from "~/components/ui/button";

const loadPosthog = () => {
  posthogJs.loadToolbar({});
};
/**
 * This is a convenience button.
 *
 * It doesn't work _perfectly_ because the PostHog toolbar is not designed to be opened and closed multiple times.
 *
 * Once you open the toolbar, if you close it, you can't reopen it with the button. You have to refresh the page.
 */
export const PosthogNavButton = () => {
  return (
    <Button
      variant="secondary"
      className={cn("cursor-pointer", {
        hidden: !config.isDev,
      })}
      onClick={loadPosthog}
    >
      <FlaskConical className="mr-2 size-4 text-red-500" />
      PostHog
    </Button>
  );
};
